import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { RouterOutlet } from '@angular/router';
import { AlarisLanguageService, LANGUAGE_SERVICE_INJECTOR } from '@campaign-portal/components-library';
import { environment } from 'src/environments/environment';

const animationTiming = '1s ease-out';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [
		trigger('layoutChanged', [
			transition('SignInLayoutComponent <=> MainLayoutComponent', [
				style({ height: 0, opacity: 0 }),
				animate(animationTiming, style({ height: '*', opacity: 1 }))
			])
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterOutlet]
})
export class AppComponent implements OnInit {

	readonly environment = environment;

	constructor(
		@Inject(LANGUAGE_SERVICE_INJECTOR) private readonly lang: AlarisLanguageService
	) {
		this.lang = lang;
	}

	ngOnInit(): void {
		this.lang.restore();
	}

	prepareRoute(outlet: RouterOutlet): boolean {
		return outlet &&
			outlet.activatedRouteData &&
			outlet.activatedRouteData.animationState;
	}
}
