import { Injectable, NgZone } from '@angular/core';
import {
	CountryNetworkRef,
	MCCMNCCountryNetworkReadResponse,
	MCCMNCCountryNetworkVersionResponse
} from '@campaign-portal/namespace/entities/mccmnc/specs';
import {
	AlarisApiService,
	AlarisLanguageService,
	AlarisLocalStorageService,
	ErrorNotifierConfig,
	IndexedDB,
	outsideZone,
	sortData,
	Typecast
} from '@campaign-portal/components-library';
import { BehaviorSubject, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { exist } from '@campaign-portal/namespace/common/id';


const DB_NAME = 'refbook';
const DB_STORE = 'records';

@Injectable({
	providedIn: 'root'
})
export class CountriesNetworksService {

	readonly countriesMap$ = new BehaviorSubject<Map<string, Set<number>>>(new Map());
	readonly countriesList$ = new BehaviorSubject<string[]>([]);

	readonly networksMap$ = new BehaviorSubject<Map<string, Set<number>>>(new Map());
	readonly networksList$ = new BehaviorSubject<string[]>([]);

	readonly list$ = new BehaviorSubject<CountryNetworkRef<exist>[]>([]);
	readonly map$ = new BehaviorSubject<Map<exist, CountryNetworkRef<exist>>>(new Map());

	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly refresh$ = new Subject<void>();
	private readonly dbService = new IndexedDB<CountryNetworkRef<exist>>(
		DB_NAME,
		1,
		DB_STORE
	);
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly storage: AlarisLocalStorageService,
		private readonly langService: AlarisLanguageService,
		private readonly zone: NgZone
	) {
		this.refresh$
			.pipe(switchMap(() => {
				this.storage.remove(DB_NAME);
				return this.load();
			}))
			.subscribe();
	}

	get list(): CountryNetworkRef<exist>[] {
		return this.list$.getValue();
	}

	get networks(): string[] {
		return this.networksList$.getValue();
	}

	get map(): Map<exist, CountryNetworkRef<exist>> {
		return this.map$.getValue();
	}

	get countries(): string[] {
		return this.countriesList$.getValue();
	}

	get title(): string {
		return this.langService.translate('notifications.titles.mccmnc');
	}

	clear(): void {
		this.countriesMap$.next(new Map());
		this.countriesList$.next([]);

		this.networksMap$.next(new Map());
		this.networksList$.next([]);

		this.list$.next([]);

		this.dbService.drop();
		this.storage.remove(DB_NAME);
	}

	load(): Observable<MCCMNCCountryNetworkReadResponse> {
		return this.checkVersion()
			.pipe(
				switchMap((reload) => {
					if ( reload ) {
						return this.requestRefBook();
					} else {
						return this.readFromDB().pipe(outsideZone(this.zone));
					}
				}),
				tap(this.parse.bind(this))
			);
	}

	private checkVersion(): Observable<boolean> {
		return this.api.loader<MCCMNCCountryNetworkVersionResponse>(
			'MCCMNC.CountryNetwork.Version', {}, this.loading$, this.errorNotifier
		).pipe(map((resp) => {
			const version = this.storage.get(DB_NAME);
			this.storage.set(DB_NAME, resp.Data.version);
			return !version || new Date(version) < new Date(resp.Data.version);
		}));
	}

	private requestRefBook(): Observable<MCCMNCCountryNetworkReadResponse> {
		return this.api.loader<MCCMNCCountryNetworkReadResponse>(
			'MCCMNC.CountryNetwork.Read',
			{},
			this.loading$,
			this.errorNotifier
		)
			.pipe(
				switchMap((resp) => {
					return this.dbService.insert(resp.Data)
						.pipe(map((data) => {
							return { Success: resp.Success, Total: resp.Total, Data: data };
						}));
				})
			);
	}

	private readFromDB(): Observable<MCCMNCCountryNetworkReadResponse> {
		return this.dbService.read().pipe(switchMap((data) => {
			if ( !data || data.length === 0 ) {
				return this.requestRefBook();
			}
			return of({ Success: true, Total: data.length, Data: data });
		}));
	}

	private parse(resp: MCCMNCCountryNetworkReadResponse): MCCMNCCountryNetworkReadResponse {
		const { countries, networks, all } = resp.Data.reduce(({ countries, networks, all }, item) => {
			if ( countries.has(item.country) ) {
				countries.get(item.country)?.add(item.mcc);
			} else {
				countries.set(item.country, new Set<number>().add(item.mcc));
			}
			if ( item.network ) {
				if ( networks.has(item.network) && Typecast.isNumber(item.mnc) ) {
					networks.get(item.network)?.add(item.mnc);
				} else {
					const set = Typecast.isNumber(item.mnc)
						? new Set<number>().add(item.mnc)
						: new Set<number>();
					networks.set(item.network, set);
				}
			}
			all.set(item.id, item);

			return { countries, networks, all };
		}, {
			countries: new Map<string, Set<number>>(),
			networks: new Map<string, Set<number>>(),
			all: new Map<exist, CountryNetworkRef<exist>>()
		});
		this.countriesMap$.next(countries);
		this.networksMap$.next(networks);
		this.countriesList$.next(
			sortData(Array.from(this.countriesMap$.getValue().keys()))
		);
		this.networksList$.next(
			sortData(Array.from(this.networksMap$.getValue().keys()))
		);
		this.map$.next(all);
		this.list$.next(resp.Data);
		return resp;
	}

}
