import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig,
	ExtendableRefBookService,
	sortData
} from '@campaign-portal/components-library';

import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ReadResponse
} from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { ContractCompany } from '@campaign-portal/namespace/entities/contract-company/specs';

@Injectable({
	providedIn: 'root'
})
export class ContractCompaniesService extends ExtendableRefBookService<ContractCompany<exist>>
	implements AbstractCRUDService {
	readonly create = this.update;

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService
	) {
		super();
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.cc');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.cc');
	}

	override load(): Observable<ReadResponse<ContractCompany<exist>[]>> {
		return this.api.loader<ReadResponse<ContractCompany<exist>[]>>(
			'ContractCompanies.Read', {}, this.loading$, this.errorNotifier
		).pipe(map((resp) => {
			sortData(resp.Data, 'name');
			return super.process(resp);
		}));
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<ContractCompany<exist>[]>> {
		return this.api.loader<ReadResponse<ContractCompany<exist>[]>>(
			'ContractCompanies.Read', params, this.loading$, this.errorNotifier
		);
	}

	update(company: ContractCompany): Observable<CreateResponse<ContractCompany>> {
		const notify = (response: CreateResponse<ContractCompany>): void => {
			if ( response.Success ) {
				const message = this.langService.translate(
					company.id ? 'notifications.actions.update' : 'notifications.actions.create', {
						entity: this.entity,
						name: company.name
					});
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CreateResponse<ContractCompany>>(
			`ContractCompanies.${company.id ? 'Update' : 'Create'}`,
			{ Data: { Entities: [company] } }, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	delete(id: Id<exist>): Observable<DeleteResponse<ContractCompany>> {
		const params: DeleteRequest<ContractCompany<exist>> = {
			Data: {
				Ids: [id]
			}
		};
		const notify = (response: DeleteResponse<ContractCompany>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<ContractCompany>>(
			'ContractCompanies.Delete', params, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });
}
