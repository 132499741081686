import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig,
	ExtendableRefBookService
} from '@campaign-portal/components-library';

import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ReadResponse
} from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { PaymentSystem } from '@campaign-portal/namespace/entities/payment-systems/specs';

@Injectable({
	providedIn: 'root'
})
export class PaymentSystemsService extends ExtendableRefBookService<PaymentSystem<exist>>
	implements AbstractCRUDService {
	readonly create = this.update;

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService
	) {
		super();
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.paySystem');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.paySystem');
	}

	override load(): Observable<ReadResponse<PaymentSystem<exist>[]>> {
		return this.api.loader<ReadResponse<PaymentSystem<exist>[]>>(
			'PaymentSystems.Read', {}, this.loading$, this.errorNotifier
		).pipe(
			map(super.process.bind(this))
		);
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<PaymentSystem<exist>[]>> {
		return this.api.loader<ReadResponse<PaymentSystem<exist>[]>>(
			'PaymentSystems.Read', params, this.loading$, this.errorNotifier
		);
	}

	update(paymentSystem: PaymentSystem): Observable<CreateResponse<PaymentSystem>> {
		const notify = (response: CreateResponse<PaymentSystem>): void => {
			if ( response.Success ) {
				const message = this.langService.translate(
					paymentSystem.id ? 'notifications.actions.update' : 'notifications.actions.create', {
						entity: this.entity,
						name: paymentSystem.name
					});
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CreateResponse<PaymentSystem>>(
			`PaymentSystems.${paymentSystem.id ? 'Update' : 'Create'}`, {
				Data: { Entities: [paymentSystem] }
			}, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	delete(id: Id<exist>): Observable<DeleteResponse<PaymentSystem>> {
		const params: DeleteRequest<PaymentSystem<exist>> = {
			Data: {
				Ids: [id]
			}
		};
		const notify = (response: DeleteResponse<PaymentSystem>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<PaymentSystem>>(
			'PaymentSystems.Delete', params, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });
}
