import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig,
	ExtendableRefBookService,
	sortData
} from '@campaign-portal/components-library';

import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ReadResponse
} from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { Partner } from '@campaign-portal/namespace/entities/partners/specs';

@Injectable({
	providedIn: 'root'
})
export class PartnersService extends ExtendableRefBookService<Partner<exist>> implements AbstractCRUDService {
	readonly create = this.update;

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService
	) {
		super();
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.partner');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.partner');
	}

	override load(): Observable<ReadResponse<Partner<exist>[]>> {
		return this.api.loader<ReadResponse<Partner<exist>[]>>('Partners.Read', {}, this.loading$, this.errorNotifier)
			.pipe(map((resp) => {
				sortData(resp.Data, 'name');
				return super.process(resp);
			}));
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<Partner<exist>[]>> {
		return this.api.loader<ReadResponse<Partner<exist>[]>>(
			'Partners.Read', params, this.loading$, this.errorNotifier
		);
	}

	update(partner: Partner): Observable<CreateResponse<Partner>> {
		const notify = (response: CreateResponse<Partner>): void => {
			if ( response.Success ) {
				const message = this.langService.translate(
					partner.id ? 'notifications.actions.update' : 'notifications.actions.create', {
						entity: this.entity,
						name: partner.name
					});
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CreateResponse<Partner>>(
			`Partners.${partner.id ? 'Update' : 'Create'}`,
			{ Data: { Entities: [partner] } },
			this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	delete(id: Id<exist>): Observable<DeleteResponse<Partner>> {
		const params: DeleteRequest<Partner<exist>> = { Data: { Ids: [id] } };
		const notify = (response: DeleteResponse<Partner>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<Partner>>(
			'Partners.Delete', params, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });
}
